import React, { useCallback } from "react";

import BlueTitle from "../../common/titles/BlueTitle";
import Space from "../../common/Space";
import { useDevicesCondition } from "../../../hooks/useDevicesCondition";
import { widgets } from "./widgets";

export function WidgetItem({ type, ...props }) {
  const isCondition = useDevicesCondition(props?.widget?.devices);

  const Content = useCallback(() => widgets?.[type]?.(props) || null, [type]);

  return isCondition ? (
    <React.Fragment>
      {props?.widget?.title && type !== "liveFeed" && (
        <>
          <BlueTitle>{props.widget.title}</BlueTitle>
          <Space height="1rem" />
        </>
      )}
      <Content />
      {type !== "logo" && <Space height="1rem" />}
    </React.Fragment>
  ) : null;
}
