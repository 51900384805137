import React, { useState } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";

import Item from "./Item";
import ChevronRightIcon from "../../common/icons/ChevronRightIcon";
import styles from "./styles.module.scss";

export function TwoItemsSlider({ items }) {
  const [isPlaying, setIsPlaying] = useState(true);

  return (
    <CarouselProvider
      visibleSlides={2}
      step={1}
      isPlaying={isPlaying}
      interval={4000}
      totalSlides={items?.length}
      naturalSlideWidth={16}
      isIntrinsicHeight={9}
      className="mb-4 w-100"
      onMouseEnter={() => setIsPlaying(false)}
      onMouseLeave={() => setIsPlaying(true)}
    >
      <Slider className="me-n2 ms-n1 ms-sm-0">
        {Array.isArray(items) &&
          items.map((item, idx) => (
            <Slide key={idx} index={idx} className="me-2">
              <Item {...item} />
            </Slide>
          ))}
      </Slider>

      <div className={styles.controls}>
        <ButtonBack className={styles.control}>
          <ChevronRightIcon rotated />
        </ButtonBack>
        <ButtonNext className={styles.control}>
          <ChevronRightIcon />
        </ButtonNext>
      </div>
    </CarouselProvider>
  );
}
