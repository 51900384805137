import { useState, useEffect, useRef, useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap'
import FormBlockRaff from './FormBlockRaff'
import cn from 'classnames'
import styles from './styles.module.scss'
import { useRaffSubscriptionStorage } from './useRaffSubscriptionStorage'
import CheckmarkIcon from './checkmark'

export default function RaffFormModal({ formBlock }) {
	if (!formBlock) return null

	const [isSubscribed, setIsSubscribed] = useState(false)
	const [show, setShow] = useState(false)
	const timer = useRef(null)
	const handleClose = () => {
		clearTimeout(timer.current)
		setShow(false)
	}

	const { handleCount, isShowModal, hidePermanently } =
		useRaffSubscriptionStorage()

	const subscribed = useCallback(() => {
		hidePermanently()
		setIsSubscribed(true)
	}, [])

	useEffect(() => {
		timer.current = setTimeout(() => {
			setShow(true)
		}, 5000)

		return () => clearTimeout(timer.current)
	}, [])

	return (
		<Modal
			show={isShowModal && show}
			onHide={handleClose}
			onShow={handleCount}
			centered
		>
			<Modal.Body>
				{isSubscribed && (
					<>
						<div className={styles.thanksHeader}>Thank you!</div>
						<button className={cn(styles.closeButton)}>
							<CheckmarkIcon />
						</button>
					</>
				)}
				<FormBlockRaff block={formBlock} subscribed={subscribed} />
				{isSubscribed && (
					<div className='d-grid' style={{ marginTop: '40px' }}>
						<Button variant='danger' onClick={handleClose}>
							OK
						</Button>
					</div>
				)}
			</Modal.Body>
		</Modal>
	)
}
