import React, { useMemo } from "react";
import { truncate } from "lodash/string";
import styles from "./styles.module.scss";
import Space from "../../../common/Space";
import stripLink from "helpers/stripLink";
import { responsiveImage } from "helpers/cdn";
import Link from "next/link";

export default function Item({ id, link, thumbnail, title, description = "" }) {
  const shortDescription = useMemo(() => {
    if (typeof description === "string") {
      return truncate(description.replace(/(<([^>]+)>)/gi, ""), {
        length: 130,
        separator: /,? +/,
      });
    }
    return "";
  }, [description]);

  return (
    <div className={styles.root}>
      <Link href={stripLink(link)}>
        <a href={stripLink(link)} className={styles.card}>
          <img
            src={responsiveImage(thumbnail, 0, 200)}
            width="200"
            height="auto"
            alt={title}
            loading="lazy"
          />
        </a>
      </Link>
      <Space height={"1rem"} />
      <p className={styles.content}>
        <Link href={stripLink(link)}>
          <a
            href={stripLink(link)}
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></a>
        </Link>
        <span
          className={styles.excerpt}
          dangerouslySetInnerHTML={{ __html: shortDescription }}
        ></span>
      </p>
    </div>
  );
}
