import React from 'react';
import { useScript } from '../../../../hooks/useScript';
import { useWidgetForm } from '../../../../hooks/useWidgetForm';
import { useFormio } from '../../../../hooks/useFormio';

export function FormWidget({ widget }) {
  const { id, content, formData } = useWidgetForm(widget);
  const { status } = useScript('https://cdn.form.io/formiojs/formio.full.min.js');
  useFormio(status, id, formData);

  return <div id={id} dangerouslySetInnerHTML={{ __html: content }} />;
}
