import React from "react";
import { CustomHtmlWidget } from "./CustomHtmlWidget";
import { BuilderHtmlWidget } from "./BuilderHtmlWidget";
import { ImageWidget } from "./ImageWidget";
import { FormWidget } from "./FormWidget";
import { LiveFeedWidget } from "./LiveFeedWidget";
import { GoogleAdWidget } from "./GoogleAdWidget";
import { SliderWidget } from "./SliderWidget";
import { NewsWidget } from "./NewsWidget";
import { SocialWidget } from "./SocialWidget";
import { ButtonWidget } from "./ButtonWidget";
import { UGCWidget } from "./UGCWidget";
import { LogoWidget } from "./LogoWidget/LogoWidget";
import { WelcomeWidget } from "./WelcomeWidget";
import { TagsWidget } from "./TagsWidget";
import { FaithAndFamilyLiveFeedWidget } from "./FaithAndFamilyWidgets/FaithAndFamilyLiveFeedWidget";
import { FaithAndFamilyLogoWidget } from "./FaithAndFamilyWidgets/FaithAndFamilyLogoWidget";
import { FaithAndFamilyModalFormWidget } from "./FaithAndFamilyWidgets/FaithAndFamilyModalFormWidget";
import { GoogleManagerWidget } from './GoogleManagerWidget'
// import { WeatherWidget } from "./WeatherWidget/index";

export const widgets = {
  customHtml: (props) => <CustomHtmlWidget {...props} />,
  textSection: (props) => <CustomHtmlWidget {...props} />,
  builder: (props) => <BuilderHtmlWidget {...props} />,
  image: (props) => <ImageWidget {...props} />,
  form: (props) => <FormWidget {...props} />,
  liveFeed: (props) => <LiveFeedWidget {...props} />,
  googleAds: (props) => <GoogleAdWidget {...props} />,
  googleManager: (props) => <GoogleManagerWidget {...props} />,
  slider: (props) => <SliderWidget {...props} />,
  news: (props) => <NewsWidget {...props} />,
  social: (props) => <SocialWidget {...props} />,
  button: (props) => <ButtonWidget {...props} />,
  ugc: (props) => <UGCWidget {...props} />,
  logo: (props) => <LogoWidget {...props} />,
  welcome: (props) => <WelcomeWidget {...props} />,
  tags: (props) => <TagsWidget {...props} />,
  faithAndFamilyLiveFeed: (props) => <FaithAndFamilyLiveFeedWidget {...props} />,
  faithAndFamilyLogo: (props) => <FaithAndFamilyLogoWidget {...props} />,
  faithAndFamilyModalForm: (props) => <FaithAndFamilyModalFormWidget {...props} />,

  // weather: (props) => <WeatherWidget {...props} />,
};
