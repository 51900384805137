import React from "react";
import cn from "classnames";
import Link from "next/link";
import { Button } from "react-bootstrap";

import style from "./CustomHtmlWidget/styles.module.scss";

export function UGCWidget({ widget }) {
  const {
    image = "",
    width = null,
    alt = "UGC image",
    buttonText = "",
    textContent = "",
    customHtml = "",
  } = widget?.data;

  return (
    <div className="text-center">
      {textContent && (
        // <div
        //   dangerouslySetInnerHTML={{ __html: textContent }}
        //   className="text-center mb-3"
        //   // style={{ fontSize: "1.5rem" }}
        // />
        <p className="fw-bold text-center mb-3">{textContent}</p>
      )}
      {customHtml && (
        <div
          className={cn(style.root, "mb-3")}
          dangerouslySetInnerHTML={{ __html: customHtml }}
        />
      )}
      {image && (
        <Link href="/ugc">
          <a href="/ugc" className="mb-3" style={{ display: "block" }}>
            <img
              style={{ maxWidth: "100%" }}
              width={width}
              height="auto"
              src={image}
              alt={alt}
              loading="lazy"
            />
          </a>
        </Link>
      )}
      {buttonText && (
        <div className="text-center">
          <Link href="/ugc">
            <Button href="/ugc" size="sm" className="viewAll">
              {buttonText}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
