import React from "react";

import { LOGO_URL } from "config/constants";
import styles from "./styles.module.scss";
import { responsiveImage } from "../../../../helpers/cdn";

const BACKGROUND_IMG_URL =
  "https://cdn.americasvoice.news/static/images/home-logo/background.png";

export function LogoWidget({ widget }) {
  return (
    <div className={styles.wrapper}>
      <img
        width="100%"
        height="auto"
        className={styles.background}
        src={BACKGROUND_IMG_URL}
        srcSet={`
        ${responsiveImage(BACKGROUND_IMG_URL, 320)} 320w,
        ${responsiveImage(BACKGROUND_IMG_URL, 768)} 768w,
        ${responsiveImage(BACKGROUND_IMG_URL, 1200)} 1200w,
        `}
        alt="Real America's Voice background"
      />
      <img
        width="138"
        height="70"
        className={styles.logo}
        src={`${LOGO_URL}@138x70`}
        srcSet={`${LOGO_URL}@276x140 2x`}
        alt="Real America's Voice Logotype"
      />
    </div>
  );
}
