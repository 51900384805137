import React, { useState } from "react";
import cn from "classnames";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";

import Item from "./Item";

import styles from "./styles.module.scss";
import ChevronRightIcon from "../../common/icons/ChevronRightIcon";

export function NewsSingleSlider({ items, title }) {
  const [isPlaying, setIsPlaying] = useState(true);

  return (
    <div className={styles.root}>
      <CarouselProvider
        visibleSlides={1}
        step={1}
        isPlaying={isPlaying}
        interval={4000}
        totalSlides={items?.length}
        naturalSlideWidth={200}
        isIntrinsicHeight={true}
        className={cn("mb-4", styles.itemWrapper)}
        onMouseEnter={() => setIsPlaying(false)}
        onMouseLeave={() => setIsPlaying(true)}
      >
        <Slider className={styles.slider}>
          {Array.isArray(items) &&
            items.map((item, idx) => (
              <Slide key={idx} index={idx}>
                <Item {...item} />
              </Slide>
            ))}
        </Slider>

        <div className={styles.controls}>
          <ButtonBack>
            <ChevronRightIcon rotated />
          </ButtonBack>
          <ButtonNext>
            <ChevronRightIcon />
          </ButtonNext>
        </div>
      </CarouselProvider>
    </div>
  );
}
