import React from "react";

import styles from "../LogoWidget/styles.module.scss";
import { responsiveImage } from "../../../../helpers/cdn";

const BACKGROUND_IMG_URL =
  "https://cdn.americasvoice.news/static/images/home-logo/raff/logo.png";

export function FaithAndFamilyLogoWidget({ widget }) {
  return (
    <div className={styles.wrapper}>
      <img
        width="100%"
        height="auto"
        className={styles.background}
        src={BACKGROUND_IMG_URL}
        srcSet={`
        ${responsiveImage(BACKGROUND_IMG_URL, 320)} 320w,
        ${responsiveImage(BACKGROUND_IMG_URL, 768)} 768w,
        ${responsiveImage(BACKGROUND_IMG_URL, 1200)} 1200w,
        `}
        alt="Real America's Voice background"
      />
    </div>
  );
}
