import React, { useMemo, useState } from "react";
import styles from "./styles.module.scss";
import PlayButton from "../../../../common/buttons/PlayButton";
import stripLink from "helpers/stripLink";
import { responsiveImage } from "helpers/cdn";
import Link from "next/link";

export default function Item({ link, thumbnail, title }) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const videoComponents = useMemo(() => {
    return (
      <div className={styles.playBtn}>
        <PlayButton loading={loading} active={active} />
      </div>
    );
  }, [active]);

  return (
    <Link href={stripLink(link)}>
      <a
        className={styles.root}
        href={stripLink(link)}
        title={title}
        onMouseEnter={() => {
          setActive(true);
        }}
        onMouseLeave={() => {
          setActive(false);
        }}
      >
        <img
          className={styles.thumbnail}
          src={responsiveImage(thumbnail, 300)}
          width="300"
          height="auto"
          alt={title}
          loading="lazy"
        />
        <div className={styles.absolutGradient}></div>
        <h4 className={styles.title}>
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
          {videoComponents}
        </h4>
      </a>
    </Link>
  );
}
