import React from "react";

import styles from "./styles.module.scss";

const prepareRedBorderText = (text) =>
  typeof text === "string"
    ? text.split("\n").map((str, idx) => (
        <span className={styles.partText} key={idx}>
          {str}
        </span>
      ))
    : "";

export function WelcomeWidget({ widget }) {
  const { blueBackgroundText, redBorderText } = widget?.data;

  return (
    <div className={styles.wrapper}>
      {blueBackgroundText?.length > 0 && (
        <h1 className={styles.blueBackBlock}>{blueBackgroundText}</h1>
      )}
      {redBorderText?.length > 0 && (
        <p className={styles.redBorderBlock}>
          {prepareRedBorderText(redBorderText)}
        </p>
      )}
    </div>
  );
}
