import React from 'react';
import styles from './styles.module.scss';

export function BuilderHtmlWidget({ widget }) {
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: widget.data?.style }} />
      <div
        className={[styles.root, widget.data.id ? `widget-${widget.data.id}` : ''].join(' ')}
        style={{ width: 'auto', margin: 'auto' }}
        dangerouslySetInnerHTML={{ __html: widget.data?.content }}
      />
    </>
  );
}
