import React from "react";
import styles from "./styles.module.scss";
import { responsiveImage } from "helpers/cdn";
import stripLink from "helpers/stripLink";
import Link from "next/link";

export default function Item({ id, link, thumbnail, title }) {
  return (
    <Link href={stripLink(link)}>
      <a href={stripLink(link)} className={styles.card}>
        <span className={styles.thumbnail}>
          <img
            src={responsiveImage(thumbnail, 1000)}
            width="270"
            height="auto"
            srcSet={`
                ${responsiveImage(thumbnail, 270)} 270w, 
                ${responsiveImage(thumbnail, 320)} 320w, 
                ${responsiveImage(thumbnail, 530)} 530w, 
                ${responsiveImage(thumbnail, 620)} 620w, 
                ${responsiveImage(thumbnail, 740)} 740w, 
                ${responsiveImage(thumbnail, 779)} 779w, 
                ${responsiveImage(thumbnail, 945)} 945w
              `}
            sizes="((min-width: 200px) AND (max-width: 991px)) calc(100vw - 54px), 62vw"
            alt={title}
            loading="lazy"
          />
        </span>
        <div className={styles.content}>
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>
          <span className={styles.readMore}>Read More »</span>
        </div>
      </a>
    </Link>
  );
}
