import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import BlueTitle from "../../common/titles/BlueTitle";
import Space from "../../common/Space";
import { Button, Nav } from "react-bootstrap";
import Item from "./Item";
import Link from "next/link";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { responsiveImage } from "helpers/cdn";
import ChevronRightIcon from "../../common/icons/ChevronRightIcon";

export function MainSlider({ items, critical = false }) {
  return (
    <CarouselProvider
      visibleSlides={1}
      step={1}
      totalSlides={items?.length}
      naturalSlideWidth={16}
      isIntrinsicHeight={9}
    >
      <div className="position-relative">
        <ButtonBack className={cn(styles.control, styles.prev)}>
          <ChevronRightIcon rotated />
        </ButtonBack>
        <ButtonNext className={cn(styles.control, styles.next)}>
          <ChevronRightIcon />
        </ButtonNext>
        <Slider>
          {Array.isArray(items) &&
            items.map((item, idx) => (
              <Slide key={idx} index={idx}>
                <Item {...item} critical={critical} />
              </Slide>
            ))}
        </Slider>
      </div>
      <div className={cn(styles.dotsWrap, "d-none d-md-flex")}>
        <div className={cn(styles.dotsInnerWrap, "d-flex")}>
          {Array.isArray(items) &&
            items.map((item, idx) => (
              <Dot key={idx} slide={idx} className={styles.dot}>
                <img
                  width="78"
                  height="auto"
                  src={responsiveImage(item.thumbnail, 88)}
                  alt={item?.title || item?.name || "thumbnail"}
                  loading={critical ? "" : "lazy"}
                />
              </Dot>
            ))}
        </div>
      </div>
      <Space height="1.5rem" />
    </CarouselProvider>
  );
}

export default function LatestShows({ items }) {
  return (
    <div className={styles.root}>
      <BlueTitle>
        <Link href="/playlists">
          <Nav.Link className="p-0" href="/playlists">
            WATCH REAL AMERICA'S VOICE NEWS SHOWS
          </Nav.Link>
        </Link>
      </BlueTitle>
      <Space height="1rem" />

      <MainSlider items={items} />

      <Space height="1.4rem" />
      <div className={"text-center"}>
        <div>
          <Link href="/playlists">
            <Button href="/playlists" size="sm" className="viewAll">
              View All SHOWS
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
