import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useMemory from '../../../hooks/useMemory'
import { AUTH_API_URL } from '../../../config/constants'

const RAFF_LOCAL_STORAGE = 'raff_subscription_modal'
const MAX_SHOW_AMOUNT = 5

export const useRaffSubscriptionStorage = () => {
	const { get, set } = useMemory()
	const setRaffSubscriptionToStorage = (data) => {
		set(RAFF_LOCAL_STORAGE, JSON.stringify(data))
	}
	const getRaffSubscriptionFromStorage = () => {
		const data = get(RAFF_LOCAL_STORAGE)
		if (!data) return null
		return JSON.parse(get(RAFF_LOCAL_STORAGE))
	}

	const [isShowModal, setIsShowModal] = useState(getRaffSubscriptionFromStorage()?.isShow ?? true)

	const handleCount = () => {
		let raffStorageData = getRaffSubscriptionFromStorage()
		if (!raffStorageData || !Object.keys(raffStorageData).length) {
			raffStorageData = { isShow: true, count: 0 }
		}
		const { isShow, count } = raffStorageData
		if (count < MAX_SHOW_AMOUNT) count++
		if (count === MAX_SHOW_AMOUNT) isShow = false
		setRaffSubscriptionToStorage({ isShow, count })
		setIsShowModal(isShow)
	}

	const loginEmail = useSelector((state) => state?.auth?.user?.email)
	const checkEmail = async () => {
		const response = await fetch(`${AUTH_API_URL}/forms/email`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email: loginEmail }),
		})
		const { isExist } = await response.json()
		if (isExist) {
			setIsShowModal(false)
			hidePermanently()
		}
	}

	useEffect(() => {
		if (loginEmail && isShowModal) checkEmail()
	}, [loginEmail, isShowModal])

	const hidePermanently = () => {
		setRaffSubscriptionToStorage({ isShow: false, count: 0 })
	}

	return {
		handleCount,
		isShowModal,
		hidePermanently,
	}
}
