import React from "react";

import useTags from "hooks/useTags";
import TagBadges from "../../../NewShows/TagBadge";

import styles from "../../../NewShows/TagBadge/styles.module.scss";

export function TagsWidget({ widget }) {
  const { isAll = true, count = null, withLink = true } = widget?.data;
  const { tags, isFetching } = useTags();

  const tagsProp = tags ?? [];

  if (!isFetching && !tags?.length) {
    return null;
  }

  return (
    <div className="text-center">
      <TagBadges
        tags={isAll ? tagsProp : tagsProp.slice(0, count)}
        large
        className={!withLink ? styles.negativeMarginBottom : ""}
      />
    </div>
  );
}
