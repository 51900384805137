import { useWidgetForm } from '../../../hooks/useWidgetForm'
import { useScript } from '../../../hooks/useScript'
import React, { useEffect } from 'react'
import { FORMIO_ID, AUTH_API_URL } from '../../../config/constants'
import { THROW_TOAST } from '../../../reducers/toasts'

export default function FormBlockRaff({ block, subscribed }) {
	const { id, content, formData } = useWidgetForm(block)
	const { status } = useScript(
		'https://cdn.form.io/formiojs/formio.full.min.js'
	)
	useFormio(status, id, formData, subscribed)

	return <div id={id} dangerouslySetInnerHTML={{ __html: content }} />
}

function useFormio(status, id, formData, subscribed) {
	useEffect(() => {
		const href = 'https://cdn.form.io/formiojs/formio.full.min.css'
		const styleInner = `.alert-success, .has-success {display: none}
                 .alert-success.formio_block{display:block}`

		const isLoad = document.querySelector(`link[href="${href}"]`)
		if (isLoad) return

		const link = document.createElement('link')
		link.rel = 'stylesheet'
		link.href = href

		const style = document.createElement('style')
		style.innerHTML = styleInner

		document.head.append(link, style)
	}, [])

	useEffect(() => {
		if (status !== 'ready') return

		if (!window.formio) {
			window.formio = new Formio(FORMIO_ID)
		}

		const div = document
			.getElementById(id)
			?.querySelector('div[data-formgenerator]')
		
		if (div) showForm(div, formData, subscribed)
	}, [status])
}

export function showForm(div, formData, subscribed) {
	const id = +div.dataset.formId
	const form = document.createElement('form')
	div.replaceWith(form)
	if (div.id) {
		form.id = div.id
	}

	const { schema, thankYouMessage, hideFormAfterSubmit } = formData
	Formio.createForm(form, schema).then(async (form) => {
		form.on('submit', async (submitData) => {
			const { data } = submitData
			delete data.submit

			try {
				await fetch(`${AUTH_API_URL}/forms/${id}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ data }),
				})
				form.emit('submitDone', null)
			} catch (e) {
				dispatch({
					type: THROW_TOAST,
					data: { type: 'error', message: e },
				})
			}
		})

		form.on('submitDone', () => {
			subscribed()
			if (hideFormAfterSubmit) {
				form.element.childNodes[1].remove()
				form.element.style.height = 'auto'
			}

			if (thankYouMessage) {
				form.element.childNodes[0].classList.add('formio_block')

				form.element.childNodes[0].innerHTML = thankYouMessage
				form.element.childNodes[0].style.textAlign = 'center'
			}

			const submitButtons = form.element.querySelectorAll(
				'button[type="submit"]'
			)

			if (Array.isArray(submitButtons)) {
				submitButtons.forEach((btn) => (btn.disabled = true))
			}
		})
	})
}
