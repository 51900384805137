import { NewsSingleSlider } from "../../blocks/NewsSlider/NewsSingleSlider";
import { Col, Row } from "react-bootstrap";
import Item from "../../Home/Sections/MoreNews/Item";
import React from "react";
import { chunk } from "lodash/array";
import { TwoItemsSlider } from "../../blocks/TwoItemsNewsSlider/TwoItemsSlider";
import ThreeItemsSlider from "../../blocks/ThreeItemsSlider/LatesVideoSlider";

export function NewsWidget(props) {
  if (
    props?.widget?.data &&
    props?.widget?.data?.items &&
    props?.widget?.data?.displayMethod
  ) {
    const { displayMethod, items } = props.widget.data;

    switch (displayMethod) {
      case "slider":
        switch (props.widget.data.itemsToShow) {
          case 1:
            return <NewsSingleSlider items={items} />;
          case 2:
            return <TwoItemsSlider items={items} />;
          case 3:
            return <ThreeItemsSlider items={items} />;
          default:
            return <></>;
        }
      case "grid":
        return <NewsGrid items={items} />;
    }
  }

  return <></>;
}

function NewsGrid({ items }) {
  if (Array.isArray(items))
    return items.map((grid, index) => (
      <GridItem
        key={index}
        type={grid.gridType}
        items={chunk(grid.items, grid.gridType)}
      />
    ));
  return null;
}

function GridItem({ type, items }) {
  switch (+type) {
    case 1:
      return (
        <Row>
          {Array.isArray(items) &&
            items.map((item) => (
              <Col key={item[0].id} xs={12}>
                <Item {...item[0]} />
              </Col>
            ))}
        </Row>
      );
    case 2:
      return (
        <Row>
          {Array.isArray(items) &&
            items.map((item) => (
              <React.Fragment key={item[0].id}>
                <Col xs={12} md={6}>
                  <Item {...item[0]} size="medium" />
                </Col>
                <Col xs={12} md={6}>
                  <Item {...item[1]} size="medium" />
                </Col>
              </React.Fragment>
            ))}
        </Row>
      );
    case 3:
      return (
        <Row>
          {Array.isArray(items) &&
            items.map((item) => (
              <React.Fragment key={item[0].id}>
                <Col xs={12} md={4}>
                  <Item {...item[0]} size="small" />
                </Col>
                <Col xs={12} md={4}>
                  <Item {...item[1]} size="small" />
                </Col>
                <Col xs={12} md={4}>
                  <Item {...item[2]} size="small" />
                </Col>
              </React.Fragment>
            ))}
        </Row>
      );
    default:
      return <></>;
  }
}
