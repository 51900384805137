import React from "react";

import { WidgetItem } from "./WidgetItem";

function ContentWidgetsSection({ widgets }) {
  if (!widgets || widgets.length === 0) {
    return <></>;
  }

  return (
    <div style={{ width: "100%" }}>
      {Array.isArray(widgets) &&
        widgets.map((widget, index) => (
          <WidgetItem key={index} widget={widget} type={widget.type} />
        ))}
    </div>
  );
}

export default ContentWidgetsSection;
