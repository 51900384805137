import Link from "next/link";
import { Button } from "react-bootstrap";
import Space from "../../common/Space";
import React from "react";

export function ButtonWidget({ widget: { data } }) {
  return (
    <div className={"text-center"}>
      <Link href={data.link}>
        <Button
          href={data.link}
          size="sm"
          className="viewAll"
          target={data.target ? "_blank" : "_self"}
        >
          {data.title}
        </Button>
      </Link>
      <Space height="1rem" />
    </div>
  );
}
