const prepareShowLink = (link = "") => {
  if (
    typeof link === "string" &&
    link.includes("/playlists/") &&
    !link.includes("/playlists/show/")
  ) {
    link = link.replace("/playlists/", "/playlists/show/");
  }
  return link;
};

export default prepareShowLink;
