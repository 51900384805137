import React from "react";
import styles from "./styles.module.scss";
import Link from "next/link";
import { useImage } from "../../../../hooks/useImage";
import cn from "classnames";

export function ImageWidget({ widget }) {
  const { href, width, src, alt, target, fullWidth } = useImage(widget);

  return (
    <div className={cn(styles.root, {[styles.logo]: fullWidth})}>
      <Link href={href}>
        <a href={href} target={target ? "_blank" : "_self"}>
          <img
            style={{ maxWidth: "100%" }}
            width={width}
            height={"auto"}
            src={src}
            alt={alt}
            loading="lazy"
          />
        </a>
      </Link>
    </div>
  );
}
