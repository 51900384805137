import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import stripLink from "helpers/stripLink";
import { responsiveImage } from "helpers/cdn";
import Link from "next/link";

const sizes = {
  large: "((min-width: 200px) AND (max-width: 991px)) calc(100vw - 54px), 62vw",
  medium:
    "((min-width: 200px) AND (max-width: 991px)) calc(100vw - 54px), 30vw",
  small: "((min-width: 200px) AND (max-width: 991px)) calc(100vw - 54px), 20vw",
};

export default function Item({ link, thumbnail, title, size = "large" }) {
  return (
    <div
      className={cn(
        styles.root,
        size === "medium" && styles.medium,
        size === "small" && styles.small
      )}
    >
      <Link href={stripLink(link)}>
        <a href={stripLink(link)} className={styles.card}>
          <span className={styles.thumbnail}>
            {thumbnail !== "0" && (
              <img
                src={responsiveImage(thumbnail, 1000)}
                srcSet={`${responsiveImage(
                  thumbnail,
                  270
                )} 270w, ${responsiveImage(
                  thumbnail,
                  320
                )} 320w, ${responsiveImage(
                  thumbnail,
                  530
                )} 530w, ${responsiveImage(
                  thumbnail,
                  620
                )} 620w, ${responsiveImage(
                  thumbnail,
                  740
                )} 740w, ${responsiveImage(
                  thumbnail,
                  779
                )} 779w, ${responsiveImage(thumbnail, 945)} 945w`}
                sizes={sizes[size]}
                width="1000"
                height="auto"
                alt={title}
                loading="lazy"
              />
            )}
          </span>
          <span className={styles.content}>
            <h2
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
            <span className={styles.readMore}>Read More »</span>
          </span>
        </a>
      </Link>
    </div>
  );
}
