import React, { useEffect, useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import ChevronRightIcon from "../../../common/icons/ChevronRightIcon";
import Item from "./Item";
import styles from "./styles.module.scss";

export default function ThreeItemsSlider({ items }) {
  const [isPlaying, setIsPlaying] = useState(true);
  const [visibleSlides, setVisibleSlides] = useState(3);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth >= 767) {
        setVisibleSlides(3);
      } else if (window.innerWidth < 767 && window.innerWidth >= 480) {
        setVisibleSlides(2);
      } else {
        setVisibleSlides(1);
      }
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    window.innerWidth >= 767 && setVisibleSlides(3);
    window.innerWidth < 767 && window.innerWidth >= 480 && setVisibleSlides(2);
    window.innerWidth < 480 && setVisibleSlides(1);
  }, []);

  return (
    <CarouselProvider
      visibleSlides={visibleSlides}
      step={1}
      isPlaying={isPlaying}
      interval={4000}
      totalSlides={items?.length}
      naturalSlideWidth={16}
      isIntrinsicHeight={9}
      className="mb-4 w-100"
      onMouseEnter={() => setIsPlaying(false)}
      onMouseLeave={() => setIsPlaying(true)}
    >
      <Slider className="me-n2 ms-n1 ms-sm-0">
        {Array.isArray(items) &&
          items.map((item, idx) => (
            <Slide key={idx} index={idx} className="me-2">
              <Item {...item} />
            </Slide>
          ))}
      </Slider>

      <div className={styles.controls}>
        <ButtonBack className={styles.control}>
          <ChevronRightIcon rotated />
        </ButtonBack>
        <ButtonNext className={styles.control}>
          <ChevronRightIcon />
        </ButtonNext>
      </div>
    </CarouselProvider>
  );
}
