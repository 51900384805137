import React from "react";
import cn from "classnames";
import Link from "next/link";

import styles from "./styles.module.scss";
import { responsiveImage } from "helpers/cdn";
import prepareShowLink from "../../../../helpers/prepareShowLink";

export default function Item({
  link,
  thumbnail,
  title = "",
  name = "",
  critical,
}) {
  const preparedLink = prepareShowLink(link);

  return (
    <Link href={preparedLink}>
      <a href={preparedLink} className={cn(styles.card)}>
        <span
          className={styles.thumbnail}
          aria-label={title || name || "thumbnail"}
        >
          <img
            width="100%"
            height="auto"
            src={thumbnail}
            srcSet={`
                ${responsiveImage(thumbnail, 380)} 380w, 
                ${responsiveImage(thumbnail, 575)} 575w, 
                ${responsiveImage(thumbnail, 767)} 767w, 
                ${responsiveImage(thumbnail, 1000)} 1000w
              `}
            sizes="(max-width: 380px) 380px, (max-width: 575px) 575px, (max-width: 767px) 767px, 1000px"
            alt={title || name || "thumbnail"}
            loading={critical ? "" : "lazy"}
          />
        </span>
      </a>
    </Link>
  );
}
